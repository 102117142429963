<template>
  <b-nav-item
    :to="{ name:'sales_without_guide'}"
  >
    <feather-icon
      id="bookmark-sale"
      :badge="totalNotifications"
      icon="ShoppingCartIcon"
      size="21"
    />
    <b-tooltip
      triggers="hover"
      target="bookmark-sale"
      title="Ventas sin guía"
      :delay="{ show: 1000, hide: 50 }"
    />
  </b-nav-item>
</template>

<script>
import { BNavItem, BTooltip } from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import saleStoreModule from '@/views/dramox/sale/saleStoreModule'

export default {
  name: 'SaleWithoutGuide',
  components: {
    BNavItem, BTooltip,
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-sales'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, saleStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })
    const totalNotifications = ref(0)

    store.dispatch('app-sales/fetchSalesWithoutGuideTotal')
      .then(response => {
        const { results } = response.data.payload
        totalNotifications.value = results
      })
      .catch(error => {
        console.log(error)
      })
    return {
      totalNotifications,
    }
  },
}
</script>

<style scoped>

</style>
