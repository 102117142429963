<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        id="bookmark-activity"
        :badge="totalNotifications"
        class="text-body"
        icon="ClockIcon"
        size="21"
      />
      <b-tooltip
        triggers="hover"
        :target="`bookmark-activity`"
        title="Ver actividades"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Actividades
        </h4>
        <b-badge
          pill
          variant="light-primary"
        >
          {{ totalNotifications }} Nuevas
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- Account Notification -->
      <b-link
        v-for="notification in notifications"
        :key="notification.id"
      >
        <b-media>
          <template #aside>
            <b-avatar
              size="32"
              :src="require('@/assets/images/avatars/avatar-3.png')"
              :text="notification.avatar"
              :variant="notification.type"
            />
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.subject }}
            </span>
          </p>
          <small class="notification-text">{{ notification.category_name }}</small>
          <p class="notification-text mb-0">
            {{ notification.created_format_humans }}
          </p>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        :to="{ name: 'activities'}"
      >Ver todas las actividades</b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton, BTooltip,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import activityStoreModule from '@/views/dramox/activities/activityStoreModule'
import { onUnmounted, ref } from '@vue/composition-api'
import router from '@/router'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BTooltip,
  },
  directives: {
    Ripple,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-activities'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, activityStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })
    const notifications = ref([])
    const totalNotifications = ref(0)

    store.dispatch('app-activities/fetchNotificationActivities')
      .then(response => {
        const { total, activities } = response.data.payload.results
        notifications.value = activities
        totalNotifications.value = total
      })
      .catch(error => {
        console.log(error)
      })

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      notifications,
      totalNotifications,
      perfectScrollbarSettings,
    }
  },
}
</script>

<style>

</style>
