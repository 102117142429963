<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ userData.business_type.name || userData.lastname }}
        </p>
        <span class="user-status">Linea de negocio</span>
      </div>
      <feather-icon
        size="16"
        icon="ChevronDownIcon"
        class="mr-50"
      />
    </template>
    <!--    <b-dropdown-divider />-->
    <b-dropdown-item
      v-if="userData.business_type.id !== 1"
      link-class="d-flex align-items-center"
      @click="changeBusinessType(1)"
    >
      <feather-icon
        size="16"
        icon="UserIcon"
        class="mr-50"
      />
      <span>Empresas</span>
    </b-dropdown-item>

    <b-dropdown-item
      v-else
      link-class="d-flex align-items-center"
      @click="changeBusinessType(2)"
    >
      <feather-icon
        size="16"
        icon="ShoppingCartIcon"
        class="mr-50"
      />
      <span>Distribuidores</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BAvatar,
} from 'bootstrap-vue'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      avatarText,
    }
  },
  methods: {
    async changeBusinessType(typeId) {
      try {
        const response = await this.$http.post('admin/change/business-type', {
          business_type_id: typeId,
        })
        const { id, name } = response.data.payload.results
        this.userData.business_type = { id, name }
        localStorage.setItem('userData', JSON.stringify(this.userData))

        window.location.reload()
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
